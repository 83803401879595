// import React, { useState } from "react";
// import { Col, Container, Row, Modal, Card } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// const AvatarSettings = () => {
//   const [openAvatarModel, setOpenAvatarModel] = useState(false);
//   const [avatars, setAvatars] = useState([]);
//   const { threadSettings, setThreadSettings,videoAvatar,setVideoAvatar } = useWorkspace();
//   const token = localStorage.getItem('token')

//   const handleAvatarClick = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/avatar`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch avatars");
//       }
//       const json = await response.json();
//       setAvatars(json["data"]["data"]);
//       setOpenAvatarModel(true);
//     } catch (error) {
//       console.error("Error fetching avatars:", error.message);
//       // Handle error (e.g., show error message)
//     }
//   };

//   const handleAvatarSelect = (avatar) => {
//     setOpenAvatarModel(false);
//     // Store selected avatar setting in threadSettings
//     setVideoAvatar(avatar.id);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaAvatar: avatar, // Assuming 'name' is the property that should be stored
//     }));
//   };

//   const AvatarModel = () => (
//     <Modal
//       show={openAvatarModel}
//       onHide={() => setOpenAvatarModel(false)}
//       size="lg"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Avatars</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container fluid>
//           {avatars && avatars.length === 0 ? (
//             <p>No data available!</p>
//           ) : (
//             <Row xs={4}>
//               {avatars && avatars.map((avatar) => {
//                 return (
//                   <Col key={avatar.id}>
//                     <Card
//                       className="mb-3"
//                       onClick={() => handleAvatarSelect(avatar)}
//                       style={{ cursor: "pointer",backgroundColor:'#e3b966'}}
//                     >
//                       <Card.Img variant="top" src={avatar.image_url} />
//                       <Card.Body className="d-flex align-items-center justify-content-center">
//                         <Card.Title style={{color:'black'}}>{avatar.name}</Card.Title>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 );
//               })}
//             </Row>
//           )}
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setOpenAvatarModel(false)}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleAvatarClick}
//       >
//         <i className="bi bi-person-bounding-box pe-3" />
//         Avatar{" "}
//         {threadSettings &&
//         threadSettings.synthesiaAvatar &&
//         threadSettings.synthesiaAvatar.name
//           ? `(${threadSettings.synthesiaAvatar.name})`
//           : ""}
//       </Button>
//       <AvatarModel />
//     </>
//   );
// };

// export default AvatarSettings;

import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Box, Input, InputAdornment, Button } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { defaultSettings_button_bg_color, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../../constants/colors";
import SearchIcon from '@mui/icons-material/Search';
import YourAvatar from '../../../../assets/PersonalAvatarIcon/Personal Avatar.png'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteAvatar, handleDeleteAvatarButtonClick } from "../../../../contexts/WorkspaceUtils";
import { fetchAvatars } from "./videoSettingsAPI";
import CommanModalForConformation from "../../../Common_Components/CommanModalForConformation";

const AvatarSettings = () => {
  // const [avatars, setAvatars] = useState([]);
  const theme = localStorage.getItem("theme");
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Track selected avatar
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [filterAvatars, setFilterAvatars] = useState(null)
  // const [personalAvatar, setPersonalAvatar] = useState(true)
  const [processingAvatarRequest, setprocessingAvatarRequest] = useState(true)
  const [debouncedValue, setDebouncedValue] = useState("");
  const [hoverForDeleteButton,setHoverForDeleteButton] = useState(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("Delete avatar?");
  const [modalMessage, setModalMessage] = useState("Are you sure you want to delete this personal avatar?");
  const deleteAvatarId = useRef(null)
  const { threadSettings, setThreadSettings, setVideoAvatar, avatars, setAvatars, avatarsloading, setAvatarsLoading, showVideoSettings,showPersonalAvatarModal,setShowPersonalAvatarModal,personalAvatar,setPersonalAvatar } = useWorkspace();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAvatars = async () => {
      setAvatarsLoading(true)
      try {
        const response = await fetch(`${sourceUrl}/video/avatar`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch avatars");
        }
        const json = await response.json();
        setAvatars(json["data"]["data"]);

      } catch (error) {
        console.error("Error fetching avatars:", error.message);
      }
      setAvatarsLoading(false)
    };
    if(avatars?.length === 0) {
      fetchAvatars();
    }
  }, [token]);

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
    setVideoAvatar(avatar.id);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaAvatar: avatar,
    }));
  };

  const handlePersonalAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
    setVideoAvatar(avatar.id);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaAvatar: avatar,
    }));
  }

  const handleConfirm =async () =>{
    const res = await handleDeleteAvatarButtonClick(deleteAvatarId.current)
    setOpenConfirmModal(false)
  }

  const handleCancel = () =>{
    deleteAvatarId.current = null
    setOpenConfirmModal(false)
  }

  const handleDeleteAvatarButtonClick =async () =>{
      const res = await DeleteAvatar(deleteAvatarId.current);
      console.log(res?.data)
      if(res.status){
        fetchAvatars(setAvatars, setAvatarsLoading, setPersonalAvatar)
      }else{
        console.log('Failed to delete avatar');
      }
  }

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#232746", 
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent) !important",
    },
  };

  

  const handleFilter = (value) => {
    const filterValue = avatars?.filter((item, i)=> item?.name?.toLowerCase().includes(value.toLowerCase()))
    setFilterAvatars(filterValue)
  };
      // Debounce logic using useEffect
      useEffect(() => {
        if(showVideoSettings){
          const handler = setTimeout(() => {
            setDebouncedValue(inputBoxValue);
            handleFilter(inputBoxValue);
          }, []);
          return () => {
            clearTimeout(handler);
          };
        }
    
}, [inputBoxValue]);

const renderFilterData = (filterAvatars) =>{
  return (
    filterAvatars?.map((avatar) => (
      <Grid
        item
        key={avatar.id}
        sx={{
          width: '140px',
          height: '174px',
          margin: 1, // Adds a margin around each grid item
          boxSizing: 'border-box', // Ensures padding is included in the width/height
        }}
      >
        <Card
          onClick={() => handleAvatarSelect(avatar)}
          sx={{
            cursor: "pointer",
            backgroundColor: defaultSettings_button_bg_color,
            border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
            height: '100%', // Ensure the card takes the full height
            padding:'10px',
          }}
        >
          <CardMedia
            component="img"
            alt={avatar.name}
            height="100" // Adjust the image height within the card
            image={avatar.image_url}
          />
          <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
            <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
              {avatar.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  )
}

  return (
    <>
        <Box sx={{paddingTop:"20px",}}>
        <Typography  component="p" style={{ fontSize: '16px', fontWeight: '500', marginBottom:"20px", fontFamily:"Inter" }}>
        Select Avatar
      </Typography></Box>
       <Box sx={{marginTop:"0px", padding:"0px"}}>
         <Typography sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 500, lineHeight:"20px", marginBottom:"16px" }}> Personal avatars </Typography>
         
            {/* <Card
              // onClick={() => handleAvatarSelect(avatar)}
              sx={{
                cursor: "pointer",
                backgroundColor: defaultSettings_button_bg_color,
                // border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
                height: '100%', // Ensure the card takes the full height
                padding:'10px',
              }}
            >
              <CardMedia
                component="img"
                alt={"pa"}
                height="100" // Adjust the image height within the card
                sx={{filter:"blur(8px)"}}
                image={YourAvatar}
              />
              <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
                <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
                Your Avatar
                </Typography>
              </CardContent>
            </Card> */}
            {
            !personalAvatar &&
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", border:"1px solid rgba(255, 255, 255, 0.20)", borderRadius:"16px", padding:"16px"}}>
              <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
              <Box sx={{display:"flex", flexDirection:"column", rowGap:"12px"}}>
              <Box sx={{display:"flex", flexDirection:"row", columnGap:"12px"}}>
                  <Typography component="p" sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '16px', fontStyle:"normal", fontWeight: 500, lineHeight:"normal"}}> Create your personal AI avatar </Typography>
                   <Box
                                      sx={{
                                        bgcolor: "rgba(246, 190, 107, 0.20)",
                                        border: "1px solid #F6BE6B",
                                        borderRadius: "4px",
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontFamily: "Inter",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          lineHeight: "16px",
                                          letterSpacing: "0.1px",
                                          color: "#F6BE6B",
                                          padding: "2px 4px",
                                          margin: "0px",
                                        }}
                                      >
                                        New
                                      </Typography>
                    </Box>
              </Box>
              <Typography sx={{color:'rgba(255, 255, 255, 0.80)', fontFamily:"Inter" ,fontSize:'14px',fontStyle:"normal", fontWeight:400, lineHeight:"normal"}}>Create lifelike, high quality videos with your personal digital AI clone </Typography>
              </Box>
              <Button
                            onClick={()=>{ setShowPersonalAvatarModal(true)}}
                            variant="contained"
                            sx={{
                              backgroundColor:
                                theme === "dark"
                                  ? uploadFiles_button_default_bg_color
                                  : "",
                              color: "black",
                              width: "156px",
                              height: "40px",
                              borderRadius: "8px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 12px",
                              "&:hover": {
                                backgroundColor: uploadFiles_button_hovered_bg_color,
                              },
                            }}
                          >
                            <Box
                              sx={{
                                height: "40px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  lineHeight: "20px",
                                  letterSpacing: "0.1",
                                  textAlign: "center",
                                  color: "#17182C",
                                  gap: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textTransform: "none",
                                  padding: "10px 0px",
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M5.62498 4.2L3.74998 5.25L4.79998 3.375L3.74998 1.5L5.62498 2.55L7.49998 1.5L6.44998 3.375L7.49998 5.25L5.62498 4.2ZM14.625 11.55L16.5 10.5L15.45 12.375L16.5 14.25L14.625 13.2L12.75 14.25L13.8 12.375L12.75 10.5L14.625 11.55ZM16.5 1.5L15.45 3.375L16.5 5.25L14.625 4.2L12.75 5.25L13.8 3.375L12.75 1.5L14.625 2.55L16.5 1.5ZM10.005 9.585L11.835 7.755L10.245 6.165L8.41498 7.995L10.005 9.585ZM10.7775 5.4675L12.5325 7.2225C12.825 7.5 12.825 7.9875 12.5325 8.28L3.77998 17.0325C3.48748 17.325 2.99998 17.325 2.72248 17.0325L0.967483 15.2775C0.674983 15 0.674983 14.5125 0.967483 14.22L9.71998 5.4675C10.0125 5.175 10.5 5.175 10.7775 5.4675Z" fill="#17182C"/>
                              </svg>
                               
                                <Box sx={{ margin: "0px", padding: "0px" }}>
                                Create avatar
                                </Box>
                              </Typography>
                            </Box>
              </Button>
              </Box>
              <Box sx={{margin:"0px", padding:"0px", display:"flex", flexDirection:"column", rowGap:"8px"}}>
                    <CardMedia sx={{height:"92px", width:"98px"}}
                      component="img"
                      alt={"Your avatar"}
                      image={YourAvatar}
                    />
                    <CardContent sx={{ padding: '0px', textAlign: 'center', margin:"0px", height:"14px"}}>
                      <Typography sx={{color:"#CAC4D0",fontFamily:"Inter",  fontSize:'11px', fontStyle:"normal" , fontWeight:600, lineHeight:"normal", letterSpacing:"0.082px"}}> Your avatar</Typography>
                    </CardContent>
              </Box>
            </Box>
            }
            <Box sx={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
            { personalAvatar &&
              personalAvatar?.map((avatar, index)=>{
                return(
              <Box>
                <Grid  onMouseEnter={()=>{ setHoverForDeleteButton(true); setHoveredCardIndex(index)}} onMouseLeave={()=>{setHoverForDeleteButton(false); setHoveredCardIndex(null)}} 
                 item
                 key={index+1}
                 sx={{
                   width: '140px',
                   height: '174px',
                   margin: 1, // Adds a margin around each grid item
                   boxSizing: 'border-box', // Ensures padding is included in the width/height
                 }}
               >          
              <Box sx={{position:"relative"}}>
               {
                (avatar.status === "validating" || avatar.status === "failed" || avatar.status === 'started' ) ? 
                 <Box sx={{display:"flex", position:"absolute", border: avatar.status == "failed" ? "1px solid rgba(221, 32, 37, 1)" : "1px solid #CAC4D0", backgroundColor: avatar.status == "failed" ? "rgba(221, 32, 37, 0.20)" : "rgba(202, 196, 208, 0.20)", width:"74px", height:"20px", borderRadius:"4px", color: avatar.status == "failed" ? "rgba(221, 32, 37, 1)" : "rgba(202, 196, 208, 1)", fontFamily:"Inter", fontSize:"12px", fontStyle:"normal", fontWeight:600, lineHeight:"16px", letterSpacing:"0.1px", top:"10px", left:"60px", zIndex:20, textAlign:"center", justifyContent:"center"}}>
                 {  avatar.status == "failed" ? "Failed" : "Processing"}
                </Box>
                :
                null
               }
               {
                hoverForDeleteButton && hoveredCardIndex === index  && 
                <Box
                onClick={()=>{deleteAvatarId.current = avatar.id; setOpenConfirmModal(true)}}
                variant="contained"
                sx={{
                  position:"absolute",
                  zIndex:"1000",
                  top:"5%",
                  left:"75%",
                  backgroundColor: theme === "dark" ? defaultSettings_button_bg_color : "",
                  color: "black",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: "#CDCFDF",
                    color:"#2F355F"
                  },
                }}
              >
                <DeleteIcon sx={{color:"rgba(202, 196, 208, 1)", '&:hover':{color:"#2F355F"} }}/>
              </Box>
               }
               
              <Card sx={{margin:"0px", padding:"12px",width:"144px", height:"174px" ,display:"flex", flexDirection:"column", rowGap:"8px", backgroundColor: defaultSettings_button_bg_color, borderRadius:"16px", border: "1px solid rgba(255, 255, 255, 0.10)",border: selectedAvatar?.id === avatar.id && avatar.status === 'done' ? "5px solid orange" : "none",cursor:'pointer'}} 
                onClick={() => {
                  if(avatar?.status === 'done'){
                    handlePersonalAvatarSelect(avatar)
                  }
                }}
              >
               <CardMedia sx={{height:"120px", width:"120px", filter:avatar.status === 'done'? '' : "blur(6px)"}}
                 component="img"
                 alt={"Your avatar"}
                 image={avatar?.status === 'done' && avatar?.image_url ? avatar?.thumbnail_url : YourAvatar}
               />
               <CardContent sx={{ padding: '0px', textAlign: 'center', margin:"0px", height:"14px"}}>
                 <Typography sx={{color:"#CAC4D0",fontFamily:"Inter",  fontSize:'14px', fontStyle:"normal" , fontWeight:600, lineHeight:"normal", letterSpacing:"0.082px"}}>{avatar?.name ? avatar?.name : 'Your Avatar'}</Typography>
               </CardContent>
               </Card>
             </Box>
                </Grid>
              </Box>
                )
              })
            }
            { personalAvatar &&
             <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"30px"}}>
              <Box  onClick={()=>{ setShowPersonalAvatarModal(true)}} sx={{ display:"flex", justifyContent:"center", alignItems:"center", margin:"0px", padding:"8px", width:"48px", height:"48px", backgroundColor: defaultSettings_button_bg_color, borderRadius:"50%", color:"#FFFFFF", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 600, lineHeight:"20px", letterSpacing:"0.1px", textTransform: "none", cursor:"pointer"}}>
                <AddIcon sx={{ width:"24px", height:"24px", color:"rgba(202, 196, 208, 1)"}}/>
              </Box>
            </Box>
            }
            </Box>
      </Box>
    <Grid>
    <Box sx={{marginTop:"24px"}}>
      <Typography sx={{color:"#ffffff", fontFamily:"Inter", fontSize: '14px', fontStyle:"normal", fontWeight: 500, lineHeight:"20px", marginBottom:"16px" }}> System avatars </Typography>
    </Box>
      {avatarsloading && (
        <Grid item xs={12}>
          <Typography>Loading avatars</Typography>
        </Grid>
      )}
      {avatarsloading === false && (avatars?.length === undefined || avatars?.length === 0 || avatars?.length === null)  ? (
        <Grid item xs={12}>
          <Typography>No data available!</Typography>
        </Grid>
      ) : 
        <Box>
        <Box sx={{ marginBottom: "20px", marginTop: "20px", display:"flex", flexDirection:"row" }}>
     { avatars.length > 0 && <Input
        type="text"
        disableUnderline={true}
        placeholder="Search Avatar"
        variant="outlined"
        sx={customTextFieldStyles}
        onChange={(e)=>{setInputBoxValue(e.target.value)}}
        value={inputBoxValue}
        style={{
          width: "100%",
          maxWidth: "180px",
          height: "48px",
          color: "#FFFFFF",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          padding:"0px 12px",
          boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
          '&:focus': {
          outline: 'none', 
         },
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }}  />
          </InputAdornment>
        }
      />}
        </Box>
        <Grid container spacing={2}>
        {
        inputBoxValue == "" ?
        avatars?.map((avatar) => (
          <Grid
            item
            key={avatar.id}
            sx={{
              width: '140px',
              height: '174px',
              margin: 1, // Adds a margin around each grid item
              boxSizing: 'border-box', // Ensures padding is included in the width/height
            }}
          >
            <Card
              onClick={() => handleAvatarSelect(avatar)}
              sx={{
                cursor: "pointer",
                backgroundColor: defaultSettings_button_bg_color,
                border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
                height: '100%', // Ensure the card takes the full height
                padding:'10px',
              }}
            >
              <CardMedia
                component="img"
                alt={avatar.name}
                height="100" // Adjust the image height within the card
                image={avatar.image_url}
              />
              <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
                <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
                  {avatar.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )) : renderFilterData(filterAvatars)
        }
      </Grid>
      </Box> 
      }
    </Grid>
    <CommanModalForConformation open={openConfirmModal} modalTitle={modalTitle} modalMessage={modalMessage} handleConfirm={handleConfirm} handleCancel={handleCancel}/>
    </>
  );
  
};

export default AvatarSettings;


