// import React, { useState,useEffect } from 'react';
// import MDEditor from '@uiw/react-md-editor';
// import rehypeSanitize from 'rehype-sanitize';
// import modalxIcon from '../../../../assets/modalxIcon.png'
// import AdditionalOptionForLastConversationForTrackWebside from './AdditionalOptionForLastConversationForTrackWebsite';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import axios from 'axios';
// import sourceUrl from '../../../../api/Source.api';
// function MessageForTrackWebsite({refetchClicked}) {
//   const {currentWebsiteLinkedId} = useWorkspace();
//   const token = localStorage.getItem("token");
//   // const [data, setData] = useState([
//   //   {
//   //     id: 1,
//   //     content:
//   //       "Java is a high-level, object-oriented programming language originally developed by Sun Microsystems and released in 1995. It is designed to have as few implementation dependencies as possible, making it a versatile and popular language for many types of software development. Java is widely used for building enterprise-scale applications, mobile applications (especially Android apps), web applications, and more.\\n\\nSome key features of Java include:\\n\\n1. **Platform Independence**: Java programs are compiled into bytecode that can be run on any device equipped with a Java Virtual Machine (JVM), regardless of the underlying hardware and operating system.\\n\\n2. **Object-Oriented**: Java is an object-oriented language, which means it helps developers create modular and reusable code.\\n\\n3. **Robust and Secure**: Java offers strong memory management, exception handling, and a garbage collection mechanism to ensure robust application performance. Additionally, it has in-built security features.\\n\\n4. **Rich API**: Java provides a set of comprehensive libraries that simplify the development of network, input/output, and graphical user interface (GUI) applications.\\n\\n5. **Multi-threaded**: Java supports multi-threading, allowing developers to write programs that perform multiple tasks simultaneously.\\n\\nJava remains a popular choice for developers due to its reliability, simplicity, and extensive community support",
//   //   },
//   //   {
//   //     id: 2,
//   //     content:
//   //       "Java is a high-level, object-oriented programming language originally developed by Sun Microsystems and released in 1995. It is designed to have as few implementation dependencies as possible, making it a versatile and popular language for many types of software development. Java is widely used for building enterprise-scale applications, mobile applications (especially Android apps), web applications, and more.\\n\\nSome key features of Java include:\\n\\n1. **Platform Independence**: Java programs are compiled into bytecode that can be run on any device equipped with a Java Virtual Machine (JVM), regardless of the underlying hardware and operating system.\\n\\n2. **Object-Oriented**: Java is an object-oriented language, which means it helps developers create modular and reusable code.\\n\\n3. **Robust and Secure**: Java offers strong memory management, exception handling, and a garbage collection mechanism to ensure robust application performance. Additionally, it has in-built security features.\\n\\n4. **Rich API**: Java provides a set of comprehensive libraries that simplify the development of network, input/output, and graphical user interface (GUI) applications.\\n\\n5. **Multi-threaded**: Java supports multi-threading, allowing developers to write programs that perform multiple tasks simultaneously.\\n\\nJava remains a popular choice for developers due to its reliability, simplicity, and extensive community support",
//   //   },
//   //   {
//   //     id: 3,
//   //     content:
//   //       "Java is a high-level, object-oriented programming language originally developed by Sun Microsystems and released in 1995. It is designed to have as few implementation dependencies as possible, making it a versatile and popular language for many types of software development. Java is widely used for building enterprise-scale applications, mobile applications (especially Android apps), web applications, and more.\\n\\nSome key features of Java include:\\n\\n1. **Platform Independence**: Java programs are compiled into bytecode that can be run on any device equipped with a Java Virtual Machine (JVM), regardless of the underlying hardware and operating system.\\n\\n2. **Object-Oriented**: Java is an object-oriented language, which means it helps developers create modular and reusable code.\\n\\n3. **Robust and Secure**: Java offers strong memory management, exception handling, and a garbage collection mechanism to ensure robust application performance. Additionally, it has in-built security features.\\n\\n4. **Rich API**: Java provides a set of comprehensive libraries that simplify the development of network, input/output, and graphical user interface (GUI) applications.\\n\\n5. **Multi-threaded**: Java supports multi-threading, allowing developers to write programs that perform multiple tasks simultaneously.\\n\\nJava remains a popular choice for developers due to its reliability, simplicity, and extensive community support",
//   //   },
//   //   {
//   //     id: 4,
//   //     content:
//   //       "Java is a high-level, object-oriented programming language originally developed by Sun Microsystems and released in 1995. It is designed to have as few implementation dependencies as possible, making it a versatile and popular language for many types of software development. Java is widely used for building enterprise-scale applications, mobile applications (especially Android apps), web applications, and more.\\n\\nSome key features of Java include:\\n\\n1. **Platform Independence**: Java programs are compiled into bytecode that can be run on any device equipped with a Java Virtual Machine (JVM), regardless of the underlying hardware and operating system.\\n\\n2. **Object-Oriented**: Java is an object-oriented language, which means it helps developers create modular and reusable code.\\n\\n3. **Robust and Secure**: Java offers strong memory management, exception handling, and a garbage collection mechanism to ensure robust application performance. Additionally, it has in-built security features.\\n\\n4. **Rich API**: Java provides a set of comprehensive libraries that simplify the development of network, input/output, and graphical user interface (GUI) applications.\\n\\n5. **Multi-threaded**: Java supports multi-threading, allowing developers to write programs that perform multiple tasks simultaneously.\\n\\nJava remains a popular choice for developers due to its reliability, simplicity, and extensive community support",
//   //   },

//   // ]);
//   const [data,setData] = useState([]);
//   useEffect(() => {
//     getSingleWebTrackDetails(currentWebsiteLinkedId);
//   }, [currentWebsiteLinkedId,refetchClicked])

//   const getSingleWebTrackDetails = async (id) => {
//     try {
//       const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`,{
//         headers:{
//           "Authorization":`Bearer ${token}`,
//           "Content-Type":"application/json"
//         }
//       })
//       setData(res.data.data.notifications);
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   const  datalength = data?.length;

//   const decodeUnicode = (str) => {
//     const stringNewLine = str?.split('\\n').join('\n');
//     let decodedString = stringNewLine?.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match?.replace(/\\?u/g, '');
//       return String.fromCharCode(parseInt(hexCode, 16));
//     });
//     return decodedString?.replace(/\\/g, '');
//   };

//   const formatDateTime = (dateString) => {
//     const options = { 
//       year: 'numeric', 
//       month: 'long', 
//       day: 'numeric', 
//       hour: 'numeric', 
//       minute: 'numeric', 
//       hour12: true 
//     };
//     const date = new Date(dateString);
//     return date.toLocaleString('en-US', options);
//   };

//   const formatDate = (dateString) => {
//     const options = { 
//       year: 'numeric', 
//       month: 'long', 
//       day: 'numeric' 
//     };
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', options);
//   };
  
//   // const viewedTime = formatDateTime(item.userViewedTime);

//   return (
//     <div style={{ padding: '0px', display: 'flex', justifyContent: 'center'}}>
//       <div style={{marginLeft:'-48px',marginTop:'20px'}}>
//         {data?.map((item, index) => (
//         <div key={item.id} style={{width : '100%', display : 'flex', gap: '20px'}}>
//             <div style={{ width : '48px' , height : '48px'}}>
//                 <img src={modalxIcon} alt="" width='48px' height='48px' />
//             </div>
//             <div  style={{ marginBottom: '60px', wordBreak: 'break-word' ,maxWidth: '942px' }}>
//               <div style={{display:'flex',justifyContent:'space-between',padding:'12px 16px', backgroundColor: '#1C2039',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
//                 <div style={{fontSize:'16px',fontWeight:'700'}}>Update: {formatDate(item.userViewedTime)}</div>
//                 <div style={{fontSize:'12px',fontWeight:'500',color:'rgba(255,255,255,0.8)'}}>Checked: {formatDateTime(item.userViewedTime)}</div>
//               </div>
//               <MDEditor.Markdown
//                 source={decodeUnicode(item.text)}
//                 style={{ padding: '10px', backgroundColor: '#1C2039', color: 'white' }}
//                 className="custom-md-editor"
//                 previewOptions={{ rehypePlugins: [[rehypeSanitize]] }}
//               />
//               <AdditionalOptionForLastConversationForTrackWebside prompt={decodeUnicode(item.text)}/>
//               {/* { index === datalength-1 ? <AdditionalOptionForLastConversationForTrackWebside/> : ''} */}
                   
         
   

//           </div>
//         </div>

//         ))}
//       </div>
//     </div>
//   );
// }

// export default MessageForTrackWebsite;

import React, { useState, useEffect, useRef } from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import modalxIcon from '../../../../assets/modalxIcon.png';
import AdditionalOptionForLastConversationForTrackWebside from './AdditionalOptionForLastConversationForTrackWebsite';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import { useMediaQuery } from 'react-responsive';
import WebTrackingPagination from './WebTrackingPagination';

function MessageForTrackWebsite({ refetchClicked,currentWebData,page,onPageChange,totalPages }) {
  // const { currentWebsiteLinkedId ,} = useWorkspace();
  const token = localStorage.getItem("token");
  const isUnder992px = useMediaQuery({ maxWidth:992});
//   const [data, setData] = useState([{
//     "notificationId": 1,
//     "text": `🚀 **New Updates on Our Website!** 🚀

// Check out the latest additions:
// 1. **Dropbox Cuts 20% of Workforce** - Major layoffs announced. [Read more](https://www.wsj.com/tech/dropbox-cuts-20-of-workforce-ecdc3003)
// 2. **Ask HN: How's LangGraph for Prod?** - Community discussion on LangGraph's production readiness.
// 3. **LinkedIn sued for tracking user health data** - New privacy concerns arise. [Details](https://www.benefitspro.com/2024/10/29/linkedin-hit-with-wave-of-health-data-claims-under-california-privacy-law-412-177165)
// 4. **Static Website Hosting – Static.app** - Explore new hosting solutions. [Learn more](https://static.app/)
// 5. **Thunderbird for Android Now Available** - Thunderbird hits Android devices. [Check it out](https://blog.thunderbird.net/2024/10/thunderbird-for-android-8-0-takes-flight/)
// 6. **War of the Rohirrim Used Christopher Lee's Archives** - Unique approach to character revival. [Read article](https://gizmodo.com/lord-of-the-rings-war-of-the-rohirrim-saruman-voice-christopher-lee-2000517317)
// 7. **Polipo: The First Figma Compiler** - A breakthrough in design software. [Explore](https://www.polipo.io/blog/polipo-the-first-figma-compiler)
// 8. **Microsoft Update Warning** – 400M Windows PCs at risk. Urgent update recommended. [Details here](https://www.forbes.com/sites/zakdoffman/2024/10/30/warning-for-14-billion-microsoft-windows-10-windows-11-users-get-free-upgrade/)

// Stay informed and explore these topics on our website now! 🌐✨`,
//     "userViewedTime": "2024-10-31 07:41:06"
// }]);


  // const scrollRef = useRef(null);
  // const [shouldScroll, setShouldScroll] = useState(true);
  // const [isUserScrolling, setIsUserScrolling] = useState(false);

  // useEffect(() => {
  //   getSingleWebTrackDetails(currentWebsiteLinkedId);
  // }, [currentWebsiteLinkedId, refetchClicked]);

  // useEffect(() => {
  //   // Automatically scroll to bottom smoothly after data is set
  //   if (scrollRef.current && shouldScroll) {
  //     scrollToBottomSmoothly();
  //   }
  // }, [data]);

  // const getSingleWebTrackDetails = async (id) => {
  //   try {
  //     const res = await axios.get(`${sourceUrl}/websiteTracking/get/${id}`, {
  //       headers: {
  //         "Authorization": `Bearer ${token}`,
  //         "Content-Type": "application/json"
  //       }
  //     });
  //     setWebsiteTrackingData(res.data.data.notifications);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const scrollToBottomSmoothly = () => {
  //   if (!scrollRef.current) return; // Ensure scrollRef.current is not null
  //   const duration = 1000; // Duration in milliseconds
  //   const start = scrollRef.current.scrollTop;
  //   const end = scrollRef.current.scrollHeight;
  //   const change = end - start;
  //   const increment = 5; // Increment in milliseconds

  //   let currentTime = 0;

  //   const animateScroll = () => {
  //     if (!scrollRef.current) return; // Check if the ref is still attached
  //     currentTime += increment;
  //     const val = easeInOutQuad(currentTime, start, change, duration);
  //     scrollRef.current.scrollTop = val;

  //     if (currentTime < duration) {
  //       requestAnimationFrame(animateScroll);
  //     }
  //   };

  //   animateScroll();
  // };

  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2;
  //   if (t < 1) return (c / 2) * t * t + b;
  //   t--;
  //   return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  const decodeUnicode = (str) => {
    const stringNewLine = str?.split('\\n').join('\n');
    let decodedString = stringNewLine?.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match?.replace(/\\?u/g, '');
      return String.fromCharCode(parseInt(hexCode, 16));
    });
    return decodedString?.replace(/\\/g, '');
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return 'Invalid Date';
  
    // Parse the date and time manually
    const [datePart, timePart] = dateString.split(' '); // Split into date and time parts
    const [year, month, day] = datePart.split('-'); // Extract date components
    const [hour, minute, second] = timePart?.split(':') || []; // Extract time components (if available)
  
    // Ensure all components are valid
    if (!year || !month || !day || !hour || !minute) return 'Invalid Date';
  
    // Create a new date object using Date.UTC
    const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second || 0));
  
    // Get user's timezone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    // Return formatted date and time based on user's timezone
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: userTimeZone, // Set the time zone dynamically
    });
  };
  

  const formatDate = (dateString) => {
    if (!dateString) return 'Invalid Date';
  
    // Parse the date manually to avoid inconsistencies
    const [datePart] = dateString.split(' '); // Extract only the date part (YYYY-MM-DD)
    const [year, month, day] = datePart.split('-'); // Split into components
  
    // Ensure the date components are valid
    if (!year || !month || !day) return 'Invalid Date';
  
    // Create a new date object using Date.UTC for consistency
    const date = new Date(Date.UTC(year, month - 1, day));
  
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  // const handleScroll = () => {
  //   if (scrollRef.current) {
  //     const scrollTop = scrollRef.current.scrollTop;
  //     const maxScrollTop = scrollRef.current.scrollHeight - scrollRef.current.clientHeight;

  //     // Determine if user is scrolling
  //     setIsUserScrolling(scrollTop < maxScrollTop);

  //     // Update shouldScroll based on user's position
  //     if (scrollTop >= maxScrollTop) {
  //       setShouldScroll(true);
  //     } else {
  //       setShouldScroll(false);
  //     }
  //   }
  // };

  return (
    <div style={{ padding: '0px', display: 'flex', justifyContent: 'center' }}>
      {
        !isUnder992px ? 
        <div style={{ marginTop: '40px' }}>
        <div
          // ref={scrollRef}
          style={{
            overflowY: 'auto',
            // maxHeight: '400px',
            padding: '10px',
          }}
          // onScroll={handleScroll}
        >
          {currentWebData?.map((item) => (
            <div key={item.id} style={{ width: '100%', display: 'flex', gap: '20px' }}>
              <div style={{ width: '48px', height: '48px' }}>
                <img src={modalxIcon} alt="" width='48px' height='48px' />
              </div>
              <div style={{ marginBottom: '60px', wordBreak: 'break-word', maxWidth: '942px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 16px', backgroundColor: '#1C2039', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                  <div style={{ fontSize: '16px', fontWeight: '700' }}>Update: {formatDate(item.created)}</div>
                  <div style={{ fontSize: '12px', fontWeight: '500', color: 'rgba(255,255,255,0.8)' }}>Checked: {formatDateTime(item.created)}</div>
                </div>
                <MDEditor.Markdown
                  source={decodeUnicode(item.text)}
                  style={{ padding: '12px 16px', backgroundColor: '#1C2039', color: 'white', minWidth: '942px'}}
                  className="custom-md-editor"
                  previewOptions={{ rehypePlugins: [[rehypeSanitize]] }}
                />
                <AdditionalOptionForLastConversationForTrackWebside prompt={decodeUnicode(item.text)} />
              </div>
            </div>
          ))}
        </div>
        <div style={{width:'100%'}}>
              <WebTrackingPagination
                totalPages={totalPages}
                currentPage={page}
                onPageChange={onPageChange}
              />
        </div>
      </div>
        :
        <div style={{ marginTop: '100px' }}>
        <div
          style={{
            overflowY: 'auto',
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignSelf:"center",
          }}
        >
          {currentWebData?.map((item) => (
            <div key={item.id} style={{ width: isUnder992px ? "90%" : '100%', display: 'flex',flexDirection:isUnder992px ? "column" : "row", gap: '20px',  margin:"auto" }}>
              <div style={{ width: '36px', height: '36px', }}>
                <img src={modalxIcon} alt="" width='36px' height='36px' />
              </div>
              <div style={{ marginBottom: '20px', wordBreak: 'break-word'}}>
                <div style={{ display:  'flex', flexDirection: isUnder992px ? "column" : "row" ,justifyContent: 'space-between', padding: '12px 16px', backgroundColor: '#1C2039', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',  minWidth:isUnder992px? '350px' : '942px' }}>
                  <div style={{ fontSize: '16px', fontWeight: '700' }}>Update: {formatDate(item.created)}</div>
                  <div style={{ fontSize: '12px', fontWeight: '500', color: 'rgba(255,255,255,0.8)' }}>Checked: {formatDateTime(item.created)}</div>
                </div>
                 <MDEditor.Markdown
                  source={decodeUnicode(item.text)}
                  style={{ padding: '12px 16px', backgroundColor: '#1C2039', color: 'white', minWidth:isUnder992px? '350px' : '942px'}}
                  className="custom-md-editor"
                  previewOptions={{ rehypePlugins: [[rehypeSanitize]] }}
                />
                <AdditionalOptionForLastConversationForTrackWebside prompt={decodeUnicode(item.text)} />
              </div>
            </div>
          ))} 
        </div>
      </div>
      }
    </div>
  );
}

export default MessageForTrackWebsite; 
