import React, { useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { trackSearchInteraction } from "../../../config/analytics";
import sourceUrl from "../../../api/Source.api";
import VoiceSettings from "./VideoGenSettings/VoiceSettings";
import LanguageSettings from "./VideoGenSettings/LanguageSettings";
import CaptionSettings from "./VideoGenSettings/CaptionSettings";
import VideoRatioSettings from "./VideoGenSettings/VideoRatioSettings";
import VideoResolution from "./VideoGenSettings/VideoResolution";
import SelectVideoGenetrationPlatform from "./SelectVideoGenetrationPlatform";
import CommonToast from "../../Common_Components/CommonToast";
import { button_bg_color_for_New_Project, hoveredSettings_Nav_bg_dm, selecetedSettings_Nav_bg_dm, settingsModal_bg_color_dm, uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color } from "../../../constants/colors";
import BackgroundSettings from "./synthesiaSettings/BackgroundSettings";
import AvatarSettings from "./synthesiaSettings/AvatarSettings";
import DIDVoiceSettings from "./synthesiaSettings/DIDVoiceSettings";
import { VideoSettingsAPI } from "./synthesiaSettings/videoSettingsAPI";
import { useNavigate } from "react-router-dom";
import { containsMediaFile } from "../../../contexts/WorkspaceUtils";
import LanguageAndVoice from "./VideoGenSettings/LanguageAndVoice";
import LanguageAndVoices from "./synthesiaSettings/LanguageAndVoices";

const VideoSettings = () => {
  const [activeTabForVideoGen, setActiveTabForVideoGen] = useState("videoPlatform");
  const [activeTabForDID,setActiveTabForDID] = useState('videoPlatform');
  const token = localStorage.getItem('token');
  const {
    showVideoSettings,
    setShowVideoSettings,
    selectedVideoGenerationPlatform,
    setCurrentThread,
    currentThread,setWaitThread,
    setSelectedOneClickButtonFromWebtrackNotificationMsg,
    selectedOneClickButtonFromWebtrackNotificationMsg,
    setNotificationTextFromWebTracking,
    notificationTextFromWebTracking,
    setSending,
    setThreadFiles,
    setThreadId,
    setIswebTrackClicked,
    setCurrentWebsiteLinkedId,
    sendMessage,
    setHistoryFiles,clearThread,
    setAvatarsLoading,
    setVoiceLoading,
    setBackgrounds,backgrounds,
    setSelectedCategory,
    setAvatars,selectedCategory,avatars,
    setLanguages,
    setVoices,voices,
    avatarsloading,
    voiceloading,
    setCategories,categories,
    stopMessageIdRef, setVisibleStopGenerationButton,
    setPersonalAvatar
  } = useWorkspace();
  const theme = localStorage.getItem("theme");
  const [videoSettingsSaved,setVideoSettingsSaved] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (showVideoSettings) {
        setActiveTabForVideoGen("videoPlatform"); 
        setActiveTabForDID("videoPlatform")
    }
    if(selectedVideoGenerationPlatform === "presenter" && (!categories || !backgrounds || !selectedCategory || !avatars || !voices )){
      setAvatarsLoading(true)
      setVoiceLoading(true)
      VideoSettingsAPI(setCategories,setBackgrounds,setSelectedCategory ,setAvatars, setLanguages,setVoices,  avatarsloading, setAvatarsLoading, voiceloading, setVoiceLoading, setPersonalAvatar  )
    }
    return (()=>{
       setActiveTabForVideoGen("videoPlatform"); 
        setActiveTabForDID("videoPlatform")
    })
  }, [showVideoSettings,selectedVideoGenerationPlatform]);

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId);
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  };

  const handleSubmit = async (input) => {
    setSending(true);
    setVisibleStopGenerationButton(true)
    setWaitThread(true);
    if (input?.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setCurrentThread((prevThread) => {
      //   const updatedThread = prevThread[prevThread.length - 1]?.sender === "user"
      //     ? prevThread.slice(0, -1) // Remove the last entry if it's from the user
      //     : prevThread;
      //   return [...updatedThread, latestEntry];
      // })
      if(currentThread[currentThread.length - 1]?.sender !== "user"){
        setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      }
      
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        const url = findUrl(entryResponse.content);
        if (url && url.includes("dailylimitexceeded")) {
          const fileFormat = getFileFormat(url);
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
            sender: 'modalX',
          };
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
        }else if(stopMessageIdRef.current.includes(entryResponse.unique_id)){
          setCurrentThread((prevThread)=>[...prevThread])
          setVisibleStopGenerationButton(false);
        }
         else {
          // setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          if(containsMediaFile(entryResponse.content)){
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
          }else{
            setCurrentThread((prevThread) => [...prevThread, entryResponse]);
            setVisibleStopGenerationButton(false);
          }
        }
        setSending(false);
      } else {
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
        setVisibleStopGenerationButton(false);
      }
    }
  
    setWaitThread(false);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const handleClose = () => {
    setShowVideoSettings(false); 
  };

  const handleCloseGenerate = () => {
    setShowVideoSettings(false);
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  const handleSave = () => {
    console.log("VideoGen settings saved");
    setVideoSettingsSaved(true);
    setShowVideoSettings(false);
  };

  const removeUrlsAndBrackets = (msg) => {
    return msg.replace(/\[.*?\]\((?:https?:\/\/[^\s\)\]]+|Link)\)/g, '');
  };  

  const handleSaveAndGenerate = () => {
    setIswebTrackClicked(false);
    setCurrentWebsiteLinkedId(null);
    navigate("/workspace");
    setVideoSettingsSaved(true);
    setShowVideoSettings(false);
    handleSubmit(removeUrlsAndBrackets(notificationTextFromWebTracking));
    setSelectedOneClickButtonFromWebtrackNotificationMsg('');
    setNotificationTextFromWebTracking('');
  }

  const handleCloseToast = () => {
    setVideoSettingsSaved(false);
  };

  const handleBackgroundSelect = (background) => {
    setSelectedBackground(background);
  };

  const renderContentForVideoGen = () => {
    switch (activeTabForVideoGen) {
      case "voice":
        return <VoiceSettings />;
      case "language":
        return <LanguageSettings />;
      case "caption":
        return <CaptionSettings />;
      case "videoAspectRatio":
        return <VideoRatioSettings />;
      case "videoResolution":
        return <VideoResolution />;
      case "videoPlatform":
        return <SelectVideoGenetrationPlatform />;
      case "language&voice":
        return <LanguageAndVoice />;
      default:
        return <SelectVideoGenetrationPlatform />;
    }
  };

  const renderContentForDID = () => {
    switch (activeTabForDID) {
      case 'background':
        return <BackgroundSettings onBackgroundSelect={handleBackgroundSelect} />;
      case 'avatar':
        return <AvatarSettings />;
      case 'voice':
        return <DIDVoiceSettings />;
      case 'videoPlatform':
        return <SelectVideoGenetrationPlatform />;
        case 'language&voice':
        return <LanguageAndVoice />;
      default:
        return <BackgroundSettings onBackgroundSelect={handleBackgroundSelect} />;
    }
  };

  return (
    <div>
      <CommonToast 
        open={videoSettingsSaved}
        onClose={handleCloseToast}
        content="Video settings saved."
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = 'success'
    />
    <Modal
      open={showVideoSettings}
      onClose={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
      aria-labelledby="audio-settings-title"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    //   BackdropProps={{
    //     style: {
    //       backgroundColor:(showVideoSettings)? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.5)", // Change the opacity (0.5 is 50%)
    //     },
    //   }}
    >
      <Box
        sx={{
          width: "900px",
          height: "636px",
          backgroundColor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          bgcolor: theme === "dark" ? settingsModal_bg_color_dm  : "",
          borderRadius: "16px",
        }}
      >
        <Typography
          id="videoGen-settings-title"
          variant="h6"
          component="h2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // mb: 2,
            borderBottom: `1px solid ${theme === "dark" ? "rgba(255, 255, 255, 0.10)" : ""}`,
            padding: "16px 20px",
          }}
          style={{
            fontSize: "18px",
            fontWeight: "600",
            color: theme === "dark" ? "#FFFFFF" : "",
          }}
        >
          Video Settings
          <IconButton
            aria-label="close"
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
            sx={{
              color: (theme) => theme.palette.grey[500],
              padding:"0px"
            }}
          >
            <CloseIcon sx={{padding:"0px"}}/>
          </IconButton>
        </Typography>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {selectedVideoGenerationPlatform === 'voice-over' && <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTabForVideoGen}
            onChange={(event, newValue) => setActiveTabForVideoGen(newValue)}
            sx={{
              borderRight: 1,
              borderColor: theme === "dark" ? "rgba(255, 255, 255, 0.10)" : "",
              minWidth: "150px",
              display: "flex",
              flexDirection: "column",
              width: "223px",
              paddingTop: "20px",
              fontFamily:"Inter",
            }}
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the default indicator (blue underline)
              },
            }}
          >
            <Tab
              label={`Type (${selectedVideoGenerationPlatform})`}
              value="videoPlatform"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "videoPlatform"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            />
            {/* <Tab
              label="Voice"
              value="voice"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "voice"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            /> 
            <Tab
              label="Language"
              value="language"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "language"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            /> */}
            <Tab
              label="Caption"
              value="caption"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "caption"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            />
            <Tab
              label="Aspect Ratio"
              value="videoAspectRatio"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "videoAspectRatio"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            />
            <Tab
              label="Resolution"
              value="videoResolution"
              sx={{
                backgroundColor:
                    activeTabForVideoGen === "videoResolution"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px 4px 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            />
             <Tab label="Language & Voice"
              value="language&voice"
              sx={{
                backgroundColor:
                activeTabForVideoGen === "language&voice"
                    ? selecetedSettings_Nav_bg_dm
                    : "transparent", // Grey background for selected tab
                "&:hover": {
                  backgroundColor:
                    theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                },
                color: theme === "dark" ? "white" : "black",
                textTransform: "none",
                fontSize: "14px",
                margin: "0 20px",
                borderRadius: "8px",
                fontFamily:"Inter",
              }}
              style={{ color: "#FFFF" }}
            />
          </Tabs>}
                      {selectedVideoGenerationPlatform === 'presenter' &&
                          <Tabs
                              orientation="vertical"
                              variant="scrollable"
                              value={activeTabForDID}
                              onChange={(event, newValue) => setActiveTabForDID(newValue)}
                              sx={{
                                  borderRight: 1,
                                  borderColor: theme === 'dark' ? '#535774' : '',
                                  minWidth: '150px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '223px',
                                  paddingTop: '20px',
                                  fontFamily:"Inter",
                              }}
                              TabIndicatorProps={{
                                  style: {
                                      display: 'none', // Hide the default indicator (blue underline)
                                  },
                              }}
                          >
                              <Tab
                                  label={`Type (${selectedVideoGenerationPlatform})`}
                                  value="videoPlatform"
                                  sx={{
                                      backgroundColor: activeTabForDID === 'videoPlatform' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                                      '&:hover': {
                                          backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                                      },
                                      color: theme === 'dark' ? 'white' : 'black',
                                      textTransform: 'none',
                                      fontSize: '14px',
                                      margin: "0 20px 4px 20px",
                                      borderRadius: '8px',
                                      fontFamily:"Inter",
                                  }}
                                  style={{ color: '#FFFF' }}
                              />
                              <Tab
                                  label="Background"
                                  value="background"
                                  sx={{
                                      backgroundColor: activeTabForDID === 'background' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                                      '&:hover': {
                                          backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                                      },
                                      color: theme === 'dark' ? 'white' : 'black',
                                      textTransform: 'none',
                                      fontSize: '14px',
                                      margin: "0 20px 4px 20px",
                                      borderRadius: '8px',
                                      fontFamily:"Inter",
                                  }}
                                  style={{ color: '#FFFF' }}
                              />
                              <Tab
                                  label={
                                    <Box display="flex" alignItems="center" gap={1}>
                                        Avatar
                                        <Box
                                            sx={{
                                                bgcolor: "rgba(246, 190, 107, 0.20)",
                                                border: "1px solid #F6BE6B",
                                                borderRadius: "4px",
                                                padding: "2px 4px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    lineHeight: "16px",
                                                    letterSpacing: "0.1px",
                                                    color: "#F6BE6B",
                                                }}
                                            >
                                                New
                                            </Typography>
                                        </Box>
                                    </Box>
                                  }
                                  value="avatar"
                                  sx={{
                                      backgroundColor: activeTabForDID === 'avatar' ? selecetedSettings_Nav_bg_dm : 'transparent', // Grey background for selected tab
                                      '&:hover': {
                                          backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', // Light grey on hover
                                      },
                                      color: theme === 'dark' ? 'white' : 'black',
                                      textTransform: 'none',
                                      fontSize: '14px',
                                      margin: "0 20px 4px 20px",
                                      borderRadius: '8px',
                                      fontFamily:"Inter",
                                  }}
                                  style={{ color: '#FFFF' }}
                              />
                              {/* <Tab
                                  label="Voice"
                                  value="voice"
                                  sx={{
                                      backgroundColor: activeTabForDID === 'voice' ? selecetedSettings_Nav_bg_dm : 'transparent', 
                                      '&:hover': {
                                          backgroundColor: theme === 'dark' ? hoveredSettings_Nav_bg_dm : '', 
                                      },
                                      color: theme === 'dark' ? 'white' : 'black',
                                      textTransform: 'none',
                                      fontSize: '14px',
                                      margin: "0 20px 4px 20px",
                                      borderRadius: '8px',
                                      fontFamily:"Inter",
                                  }}
                                  style={{ color: '#FFFF' }}
                              /> */}
                              <Tab label="Language & Voice"
                                      value="language&voice"
                                      sx={{
                                        backgroundColor:
                                        activeTabForDID === "language&voice"
                                            ? selecetedSettings_Nav_bg_dm
                                            : "transparent", // Grey background for selected tab
                                        "&:hover": {
                                          backgroundColor:
                                            theme === "dark" ? hoveredSettings_Nav_bg_dm : "", // Light grey on hover
                                        },
                                        color: theme === "dark" ? "white" : "black",
                                        textTransform: "none",
                                        fontSize: "14px",
                                        margin: "0 20px",
                                        borderRadius: "8px",
                                        fontFamily:"Inter",
                                      }}
                                      style={{ color: "#FFFF" }}
                                    />
                          </Tabs>
                      }
          <Box
            sx={{
              flexGrow: 1,
              pl: 3,
              overflowY: "auto",
              maxHeight: "496px",
              width: "457px",
              pr:3,
            }}
          >
            {selectedVideoGenerationPlatform ==='voice-over' && renderContentForVideoGen()}
            {selectedVideoGenerationPlatform ==='presenter' && renderContentForDID()}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            // mt: 2,
            p: 2,
            borderTop: `1px solid ${theme === "dark" ? "rgba(255, 255, 255, 0.10)" : "#ccc"}`, // Border top added
          }}
        >
          <Button
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleClose:handleCloseGenerate}
            sx={{
              mr: 1,
              backgroundColor:
                theme === "dark" ? "" : "",
              "&:hover": {
                color:"rgba(255, 255, 255, 0.8)",
                backgroundColor:"transparent"
              },
              color: "rgba(255, 255, 255, 1)",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={selectedOneClickButtonFromWebtrackNotificationMsg === ''?handleSave:handleSaveAndGenerate}
            sx={{
              backgroundColor:
                theme === "dark" ? button_bg_color_for_New_Project : "",
              "&:hover": {
                backgroundColor: "#FFD9AA",
              },
              color: "#17182C",
              fontSize: "16px",
              fontWeight: "600",
              textTransform: "none",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {selectedOneClickButtonFromWebtrackNotificationMsg === ''?'Save':'Save and generate video'}
          </Button>
        </Box>
      </Box>
    </Modal>
    </div>
  )
}

export default VideoSettings