import axios from 'axios';
import sourceUrl from '../api/Source.api';
const token = localStorage.getItem('token');

export const sendOtp = async ({ phoneNumber, resend}) => {
  try {
    const response = await axios.post(
      `${sourceUrl}/sendOtp`,
      { phoneNumber, resend },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:`Bearer ${token}`
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};

export const verifyOtp = async ({ phoneNumber, otp }) => {
    try {
        const response = await fetch(`${sourceUrl}/verifyOtp`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization:`Bearer ${token}`
            },
            body: JSON.stringify({ phoneNumber, otp })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error verifying OTP:', error);
        return { status: false, error: 'Network error' };
    }
};

export const requestCallBack = async ({ id, phoneNumber, email }) => {
    try {
        const response = await fetch(`${sourceUrl}/requestCallBack`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ id, phoneNumber, email })
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error requesting callback:', error);
        return { status: false, error: 'Network error' };
    }
};
