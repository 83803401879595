import React, { useState,useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Messages from "./Messages";
import PromptInput from "./PromptInput";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import axios from "axios";
import sourceUrl from "../../../api/Source.api";
import ShareModal from "./ShareModal";
import { darkTheme_bg_color } from "../../../constants/colors";
import ImageSettings from "../settingBar/ImageSettings/ImageSettings";
import PptTemplates from "../settingBar/PptTemplates";
import AudioSettings from "../settingBar/AudioSettings/AudioSettings";
import WarRoomHeaderForMobileResponsive from "../mobileResponsive/WarRoomHeaderForMobileResponsive";
import {  Box, Typography, useMediaQuery } from "@mui/material";
import VideoSettings from "../settingBar/VideoSettings";
import OfferModal from "../../Common_Components/OfferModal";
import { fetchAllHistory, UserCreaditStatus } from "../../../contexts/WorkspaceUtils";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';


const MessageWindow = () => {
  const token = localStorage.getItem('token');
  const theme = localStorage.getItem('theme');
  const {currentThread,currentHistoryId,setCurrentHistoryId,Today,setToday,setYesterday,setThisWeek,setThisMonth,setPrevMonth,InvokeMatrixApi,threadId,showRightsideBar,showLeftsideBar, setSubscribe, subscribe,reloadForSummary, setReloadForSummary, setSubscribeType,Months,setMonths,Years,setYears,historyFiles,threadFiles,setShowSettingBar,setShowRightsideBar,showSettingBar} = useWorkspace();
  const [showModal, setShowModal] = useState(false);
  // const [pageName, setPageName] = useState("workspace");
  const [firsttimefetch, setFirstTimeFetch] = useState(false);
  const mobileView = useMediaQuery('(max-width:992px)');
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const files = [...(threadFiles ? [...threadFiles].reverse() : []), ...(historyFiles || [])];
  const [filesCount,setFilesCount] = useState(0);

  useEffect(() => {
    setFilesCount(files?.length);
}, [files]);

const handleClickLeftSettingBar = () => {
  setShowSettingBar(!showSettingBar);
  setShowRightsideBar(!showSettingBar);
};
  // const fetchAllHistory = async() => {
  //   try {
  //     const res = await fetch(`${sourceUrl}/chat/summary`, {
  //       method: "GET",
  //       credentials: "include",
  //       headers: {
  //         ...(isLoggedIn && { Authorization: `Bearer ${token}` })
  //       }
  //     });
  //     // console.log("print from summary",res)
  //     // console.log(res);
  //     const responseData = await res.json();
  //     if (responseData.status) { // Check if the response status is true
  //       const { today, yesterday, this_week, this_month ,last_month} = responseData.data;
  //       setToday(today || []);
  //       if(Today.length && Today.length < today.length){
  //         localStorage.setItem('current_history_id',today[0].id);
  //         setCurrentHistoryId(today[0].id);
  //       }
  //       if(!isLoggedIn){
  //         setCurrentHistoryId(today[0].id);
  //         localStorage.setItem('current_history_id',today[0].id)
  //       }
  //       setYesterday(yesterday || []);
  //       setThisWeek(this_week || []);
  //       setThisMonth(this_month || []);
  //       setPrevMonth(last_month || []) ;
  //     } else {
        // Handle API response error
      // }
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
        // await InvokeMatrixApi(res, res, 'POST',threadId, 'summary', {});
      // ===============================================================================================
    // } catch (error) {
      // console.error("Error fetching history:", error);
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        // await InvokeMatrixApi(error, error.data, 'POST',threadId, 'summary', {});
      // ===============================================================================================
      // Handle fetch error
    // }
  // }
  // if(Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0){
  //   alert("sdkbfks")
    
  // }
  // const UserCreaditStatus = async () =>{
    // console.log(token)
  //  if(token){
  //   try{
  //     const response = await axios.get(`${sourceUrl}/issubscribe`,{
  //         headers: {
  //           Authorization: `Bearer ${token}` 
  //         },
  //       })
  //     // if(response.status===200  &&  response.data.data["remaning credits"] === "User is subscribed."){
  //     //   setSubscribe(true)
  //     // }
  //     if(response.status===200  &&  response.data?.data?.status === true){
  //       setSubscribe(true)
  //     }else if (response.status===200  && response.data?.data?.status === false){
  //       setSubscribe(false)
  //     }
  //    }catch(e){
  //     console.log("User_Creadit_Status", e)
  //    }
  //  }
  //  }
  
  // For fetching the status of user subscribed
  // useEffect(()=>{
  // async function getSubscriptionStatus(){
  //     const res = await UserCreaditStatus(setSubscribe, setSubscribeType)
  //   }
  //   getSubscriptionStatus()
  // },[subscribe])


  useEffect( () => {
    if((currentThread?.length === 2 && currentThread?.every(obj => {
      const keys = Object.keys(obj);
      return  keys.includes('sender') && keys.includes('content');
  })) || reloadForSummary){
     fetchAllHistory(Today, setToday, setYesterday, setThisWeek, setThisMonth, setPrevMonth, setCurrentHistoryId,setMonths,setYears);
      setFirstTimeFetch(false)
      setReloadForSummary(false)
    }
  }, [currentThread])

  useEffect(()=>{
    if(currentHistoryId==null){
      setFirstTimeFetch(true)
    }
  },[currentHistoryId])

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const navbarStyle = {
    position: "fixed",
    top: 0,            
    left: 0,          
    width: "100%",     
    zIndex: 1000,      
    backgroundColor:theme === 'dark'?darkTheme_bg_color:'',
  };

  return (
    <Col>
      <Row>
        <Col
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 12, offset: 0 }}
          lg={{ span: 10, offset: 1 }}
          xl={{ span: 8, offset: 2 }}
          xxl={{ span: 8, offset: 2 }}
          className="pt-3 pb-2"
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            width:(showLeftsideBar && showRightsideBar)?'100%':'100%',
            margin:'auto',
            backgroundColor:theme === 'dark'?darkTheme_bg_color:'',
          }}
        >
          {currentThread?.length === 0 ? '' :
            // <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            //   <Button style={{ maxWidth: '50px',marginRight:'50px',backgroundColor:theme === 'dark'?'':''}} variant="outline-primary" onClick={handleShowModal}>
            //     {/* <i className="bi bi-share"></i> */}
            //     <i class="bi bi-upload"></i>
            //   </Button>
            // </div>
            <></>
          }
          {mobileView? <div style={navbarStyle}><WarRoomHeaderForMobileResponsive/></div> : 
            !showRightsideBar && 
              <Box
                onClick={handleClickLeftSettingBar}
                sx={{
                  position: "relative",
                  width: "140px",
                  height: "40px", // Explicit height
                  minHeight: "40px", // Prevent height shrinkage
                  boxSizing: "border-box", // Ensures padding/border don’t affect size
                  bgcolor: "#1F233E",
                  borderRadius: "24px",
                  gap: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    bgcolor: "#282D51",
                  },
                  marginLeft: "auto", // Moves the button to the rightmost side
                  marginRight:'20px',
                  cursor:'pointer'
                }}
              >
                <DriveFolderUploadOutlinedIcon sx={{ color: "rgba(202, 196, 208, 1)" }} />
                <Typography
                  sx={{
                    color: "rgba(202, 196, 208, 1)",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "16px",
                  }}
                >
                  Upload files
                </Typography>
            
                {/* Badge for the file count */}
                {filesCount > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-5px",
                      right: "-5px",
                      width: "18px",
                      height: "18px",
                      bgcolor: "#CAC4D0",
                      color: "#17182C",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "12px",
                      padding: "2px",
                      fontWeight: "500",
                    }}
                  >
                    {filesCount}
                  </Box>
                )}
              </Box>
          }
          <div style={{padding:"0px 20px 0px 20px", marginTop: mobileView ? "60px" : "0px"}}>
          {/* {subscribe === true || subscribe === null  ? null : <OfferModal pageName={pageName}/>} */}
          </div>
          <Messages />
          <div style={{boxShadow: '0px -12px 80px 0px #171a2d'}}>
            <PromptInput />
          </div>
          {/* <VideoSettings />
          <ImageSettings />
          <PptTemplates />
          <AudioSettings /> */}
          
          <div style={{textAlign:'center',color:'grey',fontSize:'12px', marginTop : '10px'}}>ModalX may have errors, verify key info</div>
        </Col>
      </Row>
      <ShareModal show={showModal} handleClose={handleCloseModal} />
    </Col>
  );
};

export default MessageWindow;
