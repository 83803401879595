// src/utils/AuthUtils.js
import { jwtDecode } from "jwt-decode";
import { auth } from "../config/firebase.config";

export const refreshAuthToken = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      const idToken = await user.getIdToken(true);
      localStorage.setItem("token", idToken);

      // Decode token to get expiry
      const decodedToken = jwtDecode(idToken);
      const expiryTime = decodedToken.exp * 1000;
      const refreshTime = expiryTime - Date.now() - 5 * 60 * 1000;

      console.log("Token will be refreshed in:", refreshTime / 1000, "seconds");

      if (refreshTime > 0) {
        setTimeout(refreshAuthToken, refreshTime);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  }
};
