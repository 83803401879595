import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mailIcon from "../../assets/signup/mail.png";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import sourceUrl from "../../api/Source.api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import CommonToast from "../Common_Components/CommonToast";
const usertoken = localStorage.getItem("token");

const VerifyEmailModal = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const [currentWindow, setCurrentWindow] = useState(1);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [notifVariant, setNotifVariant] = useState("error");
  const [notifMsg, setNotifMsg] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setToken(params.get("token"));
  }, [location]);

  const onClose = () => {
    setOpen(false);
  };

  const emailVerification = async () => {
    try {
      const response = await fetch(`${sourceUrl}/emailVerification`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${usertoken}`,
        },
        body: JSON.stringify({ token, email }),
      });
      const data = await response.json();
      console.log("EmailVerification", data);
      return data;
    } catch (err) {
      console.error(err.message);
      setOpen(true);
      setNotifVariant("error");
      setNotifMsg(err.message);
    }
  };

  const handleNavigation = () => {
    setCurrentWindow(1);
  };

  const handleCloseResendEmailModal = () => {
    navigate("/");
  };

  const handleResendEmailClick = async () => {
    const response = await emailVerification();
    if (response?.status) {
      setCurrentWindow(2);
    } else {
      setOpen(true);
      setNotifVariant("error");
      setNotifMsg("Failed to Verify Email");
    }
  };

  const handleContinueClick = async () => {
    const response = await emailVerification();
    if (response?.status) {
      setCurrentWindow(2);
    } else {
      setOpen(true);
      setNotifVariant("error");
      setNotifMsg("Failed to send verification email");
    }
  };

  const handleCancelClick = () => {
    navigate("/");
  };

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.80) !important",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(255, 255, 255, 0.40)",
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.40)", // New color
    },
  };

  return (
    <>
      <div
        style={{
          zIndex: 70,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isBelow991 ? "" : "100vw",
          height: isBelow991 ? "" : "100vh",
          left: "0%",
          top: "0%",
        }}
      >
        <div
          onClick={handleCloseResendEmailModal}
          style={{
            width: "100%",
            height: isBelow991 ? "" : "100%",
            position: isBelow991 ? "static" : "absolute",
            backgroundColor: "#101220",
            //   left: "50%",
            //   top: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={(event) => event.stopPropagation()}
            sx={{
              width: isBelow991 ? "100%" : "480px",
              // maxHeight: "488px",
              backgroundColor: isBelow991 ? "#171A2D" : "#1F233E",
              borderRadius: "8px",
              border: isBelow991 ? null : "1px solid rgba(255, 255, 255, 0.10)",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "64px",
                display: "flex",
                justifyContent: currentWindow === 1 ? "end" : "space-between",
                padding: isBelow991
                  ? "36px 20px 0px 20px"
                  : "20px 20px 0px 20px",
                alignItems: "center",
              }}
            >
              {currentWindow === 1 ? (
                <Box
                  onClick={handleNavigation}
                  sx={{
                    width: "28px",
                    height: "28px",
                  }}
                >
                  <IconButton
                    aria-label="back"
                    onClick={handleCancelClick}
                    sx={{
                      color: "#CDCDCD",
                      padding: "0px 0px",
                    }}
                  >
                    <CloseIcon
                      sx={{
                        width: "28px",
                        height: "28px",
                        padding: "0px 0px",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                    }}
                  >
                    <IconButton
                      aria-label="back"
                      onClick={handleNavigation}
                      sx={{
                        color: "#CDCDCD",
                        padding: "0px 0px",
                      }}
                    >
                      <ArrowBackIcon
                        sx={{
                          width: "28px",
                          height: "28px",
                          padding: "0px 0px",
                        }}
                      />
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                    }}
                  >
                    <IconButton
                      aria-label="back"
                      onClick={handleCancelClick}
                      sx={{
                        color: "#CDCDCD",
                        padding: "0px 0px",
                      }}
                    >
                      <CloseIcon
                        sx={{
                          width: "28px",
                          height: "28px",
                          padding: "0px 0px",
                        }}
                      />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                padding: isBelow991
                  ? "60px 24px 40px 24px"
                  : "0px 20px 20px 20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  style={{
                    width: "80px",
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255,0.05)",
                    opacity: 0.8,
                  }}
                >
                  <img
                    src={mailIcon}
                    alt="mailIcon"
                    style={{ width: "33px", height: "26px" }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    alignSelf: "stretch",
                    textAlign: "center",
                    margin: "0px 0px",
                  }}
                >
                  {currentWindow === 1
                    ? "Enter your Email address"
                    : "Verify your Email address"}
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "center",
                    maxWidth: "300px",
                  }}
                >
                  {currentWindow === 1
                    ? `To serve you better, we need to confirm your email address`
                    : `We have sent a verification link to ${email}. If you do not receive it shortly, please click Resend Mail.`}
                </Typography>
              </Box>

              {currentWindow === 1 ? (
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <TextField
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    id="outlined-basic"
                    label="Email address*"
                    variant="outlined"
                    sx={() => customTextFieldStyles}
                    style={{
                      width: "300px",
                      height: "56px",
                      color: "#FFFFFF",
                      backgroundColor: "#232746",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
                      placeholderfontsize: "16px",
                    }}
                  />
                  <FormHelperText
                    sx={{
                      maxWidth: "264px",
                      color:
                        "var(--M3-sys-dark-on-surface-variant, var(--Schemes-Outline-Variant, #CAC4D0))",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      alignSelf: "center",
                    }}
                  >
                    {
                      "ModalX will send you generated files and information on this email address."
                    }
                  </FormHelperText>
                </FormControl>
              ) : null}

              <Box
                sx={{
                  marginTop: "40px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#F6BE6B",
                    color: "#17182C",
                    padding: "10px 24px 10px 24px",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    letterSpacing: "0.1px",
                    textAlign: "center",
                    width: "300px",
                    margin: "auto",
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "#ffd9aa",
                      border: "none",
                    },
                  }}
                  onClick={
                    currentWindow ? handleContinueClick : handleResendEmailClick
                  }
                >
                  {currentWindow === 1 ? "Continue" : "Resend Email"}
                </Button>
              </Box>

              <Box
                onClick={handleNavigation}
                sx={{
                  width: "100%",
                  marginTop: "24px",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    alignSelf: "stretch",
                    textAlign: "center",
                  }}
                >
                  {currentWindow !== 1 ? "Use a different email address" : null}
                </Typography>
              </Box>
            </Box>
          </Box>
          <CommonToast
            open={open}
            onClose={onClose}
            content={notifMsg}
            iconType={notifVariant}
            bgColor={"#2F355F"}
          />
        </div>
      </div>
    </>
  );
};

export default VerifyEmailModal;
