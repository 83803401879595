//=============================================== This is the latest one =============================================================

// import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useNavigate,
// } from "react-router-dom";

// import Home from "./pages/Home";
// import Documentation from "./pages/Documentation";
// import Tools from "./pages/Tools";
// import ApiDocs from "./pages/ApiDocs";
// import Blog from "./pages/Blog";
// import Terms from "./pages/Terms";
// import Privacy from "./pages/Privacy";
// import Career from "./pages/Career";
// import UserJourney from "./pages/UserJourney";
// import Workspace from "./pages/Workspace";
// import Landing from "./pages/Landing";

// import PrivateRoute from "./routes/PrivateRoute";
// import PublicRoute from "./routes/PublicRoute";

// import AuthProvider, { useAuth } from "./contexts/AuthContext";
// import UIProvider from "./contexts/UIContext";
// import WorkspaceProvider from "./contexts/WorkspaceContext";
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import { auth } from "./config/firebase.config";

// const App = () => {
//   const [showModal, setShowModal] = useState(true); // Set initial state to true
//   const isLoggedIn = localStorage.getItem("isLoggedIn")||'' ;
//   const [modalClosedManually, setModalClosedManually] = useState(false); // State to track if modal is closed manually

//   useEffect(() => {
//     if (!isLoggedIn) {
//       // Show modal after 10 seconds if not logged in and not closed manually
//       const timer = setTimeout(() => {
//         setShowModal(true);
//       }, 10000);

//       return () => clearTimeout(timer);
//     }
//   }, [isLoggedIn, modalClosedManually]);

//   const closeModal = () => {
//     console.log("Close modal clicked"); // Debugging statement
//     setShowModal(false);
//     setModalClosedManually(true); // Set state when modal is closed manually
//   };


//   return (
//     <Router>
//       <AuthProvider>
//         <UIProvider>
//           <Routes>
//             <Route path="/userJourney" element={<PrivateRoute />}>
//               <Route path="/userJourney" element={<UserJourney />} />
//             </Route>
//             <Route path="/workspace" element={<PublicRoute />}>
//               <Route
//                 path="/workspace"
//                 element={
//                   <WorkspaceProvider>
//                     <Workspace />
//                   </WorkspaceProvider>
//                 }
//               />
//             </Route>
//             <Route path="/" element={<PublicRoute />}>
//               <Route path="/" element={<Home />} />
//               <Route path="/tools" element={<Tools />} />
//               <Route path="/landing" element={<Landing/>} />
//               <Route path="/docs" element={<Documentation />} />
//               <Route path="/api" element={<ApiDocs />} />
//               <Route path="/blog" element={<Blog />} />
//               <Route path="/terms" element={<Terms />} />
//               <Route path="/privacy" element={<Privacy />} />
//               <Route path="/career" element={<Career />} />
//             </Route>
//             <Route path="*" element={<Navigate to="/" replace />} />
//           </Routes>
//           {showModal && !isLoggedIn && (
//             <Modal closeModal={closeModal} />
//           )}
//         </UIProvider>
//       </AuthProvider>
//     </Router>
//   );
// };

// const Modal = ({ closeModal }) => {
//   const { setUser } = useAuth();
//   const navigate = useNavigate();
//   const modalStyle = {
//     position: "fixed",
//     top: 0,
//     right: 0, // Adjusted position to top right
//     margin:'50px',
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     borderRadius: "20px",
//   };

//   const contentStyle = {
//     backgroundColor: "#0d0d0d",
//     padding: "20px",
//     borderRadius: "20px",
//     position: "relative",

//   };

//   const closeStyle = {
//     position: "absolute",
//     top: "10px",
//     right: "10px",
//     cursor: "pointer",
//   };
//   const handleGoogleSignUp = async () => {
//     try {
//       const provider = new GoogleAuthProvider();
//       const result = await signInWithPopup(auth, provider);
//       if (result.user.emailVerified) {
//         localStorage.setItem("token", result.user.accessToken);
//         setUser(result.user.emailVerified);
//         localStorage.setItem("isLoggedIn", true);
//         closeModal();
//         navigate("/workspace")
//         window.location.reload();
//       } else {
//         localStorage.setItem("isLoggedIn", "");
//       }
//     } catch (error) {
//       console.error("Error signing up with Google:", error.message);
//       // Add additional error handling logic here, such as displaying an error message to the user
//     }
//   };

//   return (
//     <div style={modalStyle}>
//       <div style={contentStyle}>
//         <span style={closeStyle} onClick={closeModal}>
//           &times;
//         </span>
//         <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center',padding:'10px'}}>
//             <img src={require(`./assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)} style={{width:'15px',height:'15px'}}/>
//             <p style={{margin:'auto',fontSize:'.9rem',color:'white'}}>Sign in to Modalx.ai using google.com</p>
//         </div>
//         <p style={{color:'grey',width:'100%'}}>------------------------------------------------</p>
//         <button onClick={() =>handleGoogleSignUp()} style={{width:'100%',borderRadius:'10px',backgroundColor:'#0d6efd',fontSize:'.9rem',border:'none',padding:'10px'}}>Continue with google</button>
//       </div>
//     </div>
//   );
// };

// export default App;

//=============================================== Above code is the latest one =============================================================

import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  Outlet
} from "react-router-dom";

import Home from "./pages/Home";
import Documentation from "./pages/Documentation";
import Tools from "./pages/Tools";
import ApiDocs from "./pages/ApiDocs";
import Blog from "./pages/Blog";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Career from "./pages/Career";
import UserJourney from "./pages/UserJourney";
import Workspace from "./pages/Workspace";
import Landing from "./pages/Landing";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

import AuthProvider, { useAuth } from "./contexts/AuthContext";
import UIProvider from "./contexts/UIContext";
import WorkspaceProvider from "./contexts/WorkspaceContext";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./config/firebase.config";
import SignUpLogIn from "./components/navFoot/SignUpLogIn";
import SignUpLoginForTesting from "./components/navFoot/SignUpLoginForTesting";
import ClientLandingPage from "./components/Client_Side/ClientLandingPage";
import { CreditRechargeProvider, useCreditRecharge } from "./contexts/CreditRechageContext";
import * as Sentry from "@sentry/react";
import { initializeGA, initializeMixPanel, trackNavigation, trackPageView } from "./config/analytics";
import PublicLinkView from "./components/PublicLinkView";
import PaymentFail from "./pages/PaymentFail";
import PaymentSuccess from "./pages/PaymentSuccess";
import PresentationGallery from "./pages/PresentationGallery";
import BlogArticle from "./pages/BlogArticle";
import Aboutus from "./pages/Aboutus";
import Safety from "./pages/Safety";
import FAQ from "./pages/FAQ";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import ImageGallery from "./pages/ImageGallery";

import MarketingAgencies from './pages/MarketingAgencies'
import AudioGallery from './pages/AudioGallery'
import VideoGallery from "./pages/VideoGallery";
import UseCases from "./pages/UseCases";
import CaseStudy from "./pages/CaseStudy";
import LogOut from "./pages/LogOut";
import ShareProject from "./pages/ShareProject";
import NewPage from "./pages/NewPage";

import LogRocket from 'logrocket';
import { WorkSpaceTrack } from "./pages/WorkSpaceTrack";
import WebSiteAllUpdatePage from "./pages/WebSiteAllUpdatePage";
import { DailyLimitExceedProvider, useDailyLimitExceed } from "./contexts/DailyLimitExceedContext";
import PricePage from "./pages/PricePageNavBar";
import PaymentSuccessForPlus from "./pages/PaymentSuccessForPlus";
import HelpButtonMain from "./components/workspace/settingBar/HelpButton/HelpButtons";
import Login from "./components/navFoot/Login";
import Signup from "./components/navFoot/Signup";
import ResetPassword from "./components/navFoot/ResetPassword";
import { refreshAuthToken } from "./contexts/AuthUtils";
import BlogPage from "./pages/BlogPage";
import CallingAgent from "./pages/CallingAgent";
import NewLandingPage from "./pages/NewLandingPage";
import EmailVerificationPageFailed from "./pages/EmailVerificationPageFailed";
import VerifyEmailModal from "./components/navFoot/VerifyEmailModal";
import VerifiedEmail from "./components/navFoot/VerifiedEmail";
import PaymentSuccessForSmb from "./pages/PaymentSuccessForSmb";


LogRocket.init('ozgv5h/modalx');

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);  // Track page view
  }, [location]);

  return null;
};


const NavigationTracker = () => {

  const location = useLocation();
  const prevLocation = useRef(location.pathname);

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      trackNavigation(prevLocation.current, location.pathname);
      prevLocation.current = location.pathname;
    }
  }, [location])

  return null;
}


localStorage.setItem('theme', 'dark');


const WorkspaceLayout = () => {
  return (
    <WorkspaceProvider>
      {/* Common logic or UI for all workspace routes */}
      <Sentry.ErrorBoundary>
        {/* Render child routes here */}
        <Outlet />
        <HelpButtonMain/>
      </Sentry.ErrorBoundary>
    </WorkspaceProvider>
  );
};

const App = () => {
  const [showModal, setShowModal] = useState(true); // Set initial state to true
  const isLoggedIn = localStorage.getItem("isLoggedIn") || '';
  const [modalClosedManually, setModalClosedManually] = useState(false); //  State to track if modal is closed manually

  useEffect(() => {
    if (!isLoggedIn) {
      // Show modal after 10 seconds if not logged in and not closed manually
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [isLoggedIn, modalClosedManually]);

  const closeModal = () => {
    console.log("Close modal clicked"); // Debugging statement
    setShowModal(false);
    setModalClosedManually(true); // Set state when modal is closed manually
  };

  useEffect(() => {
    initializeGA();  // Initialize GA when the app starts
  }, []);

  
useEffect(()=>{
  initializeMixPanel();
},[])

useEffect(() => {
  const unsubscribe = auth.onAuthStateChanged((user) => {
    if (user) {
      refreshAuthToken();
    }
  });

  return () => unsubscribe();
}, []);

  return (
    <Router>
      <AuthProvider>
        <UIProvider>
          <DailyLimitExceedProvider>
          <CreditRechargeProvider>
            <RouteChangeTracker />
            <NavigationTracker />
            <Routes>
              <Route path="/userJourney" element={<PrivateRoute />}>
                <Route path="/userJourney" element={<UserJourney />} />
              </Route>

              {/* Workspace Routes */}
              <Route path="/workspace" element={<WorkspaceLayout />}>
                <Route index element={<Workspace />} /> {/* Matches exactly /workspace */}
                <Route path="trackwithmodalx" element={<WorkSpaceTrack />} />
                <Route path="trackwithmodalx/:id" element={<WebSiteAllUpdatePage />} />
                <Route path=":id" element={<Workspace />} />
              </Route>

              <Route>
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:blogTitle" element={<BlogPage />} />
              </Route>

              {/* Payment Routes */}
              <Route
                path="/paymentfail"
                element={
                  <WorkspaceProvider>
                    <PaymentFail />
                  </WorkspaceProvider>
                }
              />
              <Route
                path="/paymentsuccess"
                element={
                  <WorkspaceProvider>
                    <PaymentSuccess />
                  </WorkspaceProvider>
                }
              />
              <Route
                path="/paymentsuccess/plus"
                element={
                  <WorkspaceProvider>
                    <PaymentSuccessForPlus />
                  </WorkspaceProvider>
                }
              />
              <Route
                path="/paymentsuccess/smb"
                element={
                  <WorkspaceProvider>
                    <PaymentSuccessForSmb />
                  </WorkspaceProvider>
                }
              />

              {/* Logout Route */}
              <Route
                path="/logout"
                element={
                  <WorkspaceProvider>
                    <LogOut />
                  </WorkspaceProvider>
                }
              />

              {/* Public Routes */}
              <Route path="/" element={<PublicRoute />}>
                <Route path="/home" element={<Home/>} />
                <Route path="/" element={<Navigate to="/vic" replace />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/landing" element={<Landing />} />
                <Route path="/docs" element={<Documentation />} />
                <Route path="/api" element={<ApiDocs />} />
                {/* <Route path="/blog" element={<Blog />} /> */}
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/career" element={<Career />} />
                <Route path="/share/:token" element={<ShareProject />} />
                <Route path="/admin" element={<ClientLandingPage />} />
                <Route path="/publicview" element={<PublicLinkView />} />
                <Route path="/newpage" element={<NewPage />} />
                <Route path='/login' element={<Login/>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path='/reset' element={<ResetPassword/>}/>
                <Route path='/emailverificationfailed' element={<EmailVerificationPageFailed/>}/>
                <Route path='/vic' element={<CallingAgent />}/>
              </Route>

              {/* Other Routes */}
              <Route path="/presentationgallery" element={<PresentationGallery />} />
              <Route path="/blogarticle" element={<BlogArticle />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/termsofservice" element={<TermsOfService />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/aboutus" element={<Aboutus />} />
              <Route path="/safety" element={<Safety />} />
              <Route path="/marketingagencies" element={<MarketingAgencies />} />
              <Route path="/audiogallery" element={<AudioGallery />} />
              <Route path="/imagegallery" element={<ImageGallery />} />
              <Route path="/videogallery" element={<VideoGallery />} />
              <Route path="/usecases" element={<UseCases />} />
              <Route path="/casestudy" element={<CaseStudy />} />
              <Route path="/pricing" element={<PricePage />} />
              <Route path="/agent" element={<CallingAgent />} />
              <Route path="/agent/india" element={<NewLandingPage/>}/>
              <Route path="/verify" element={<VerifyEmailModal/>}/>
              <Route path="/emailverifedsuccess" element={<VerifiedEmail/>}/>

              {/* Fallback Route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>

            {/* {showModal && !isLoggedIn && (
            <Modal closeModal={closeModal} />
          )} */}
          </CreditRechargeProvider>
          </DailyLimitExceedProvider>
        </UIProvider>
      </AuthProvider>
    </Router>
  );
};

const Modal = ({ closeModal }) => {
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const modalStyle = {
    position: "fixed",
    top: 0,
    right: 0, // Adjusted position to top right
    margin: '50px',
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    borderRadius: "20px",
  };

  const contentStyle = {
    backgroundColor: "#0d0d0d",
    padding: "20px",
    borderRadius: "20px",
    position: "relative",

  };

  const closeStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  };
  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user.emailVerified) {
        localStorage.setItem("token", result.user.accessToken);
        setUser(result.user.emailVerified);
        localStorage.setItem("isLoggedIn", true);
        closeModal();
        navigate("/workspace")
        window.location.reload();
      } else {
        localStorage.setItem("isLoggedIn", "");
      }
    } catch (error) {
      console.error("Error signing up with Google:", error.message);
      // Add additional error handling logic here, such as displaying an error message to the user
    }
  };

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <span style={closeStyle} onClick={closeModal}>
          &times;
        </span>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', padding: '10px' }}>
          <img src={require(`./assets/transparent-google-logo-google-logo-with-multicolored-g-and-1710875781697.png`)} style={{ width: '15px', height: '15px' }} />
          <p style={{ margin: 'auto', fontSize: '.9rem', color: 'white' }}>Sign in to Modalx.ai using google.com</p>
        </div>
        <p style={{ color: 'grey', width: '100%' }}>------------------------------------------------</p>
        <button onClick={() => handleGoogleSignUp()} style={{ width: '100%', borderRadius: '10px', backgroundColor: '#0d6efd', fontSize: '.9rem', border: 'none', padding: '10px' }}>Continue with google</button>
      </div>
    </div>
  );
};

export default App;


          // <Routes>
          //     <Route path="/userJourney" element={<PrivateRoute />}>
          //       <Route path="/userJourney" element={<UserJourney />} />
          //     </Route>
          //     <Route path="/workspace" element={<PublicRoute />}>
          //       <Route
          //         path="/workspace/:id"
          //         element={
          //           <Sentry.ErrorBoundary>
          //             <WorkspaceProvider>
          //               <Workspace/>
          //             </WorkspaceProvider>
          //           </Sentry.ErrorBoundary>

          //         }
          //       />
          //       <Route path="/workspace" element={<WorkspaceLayout />}>
          //         <Route path="" element={<Workspace />} />
          //         <Route path="trackwithmodalx" element={<WorkSpaceTrack />} />
          //         <Route path="trackwithmodalx/:id" element={<WebSiteAllUpdatePage />} />
          //       </Route>
                 
          //     </Route>
          //     <Route
          //       path="/paymentfail"
          //       element={
          //         <WorkspaceProvider>
          //           <PaymentFail />
          //         </WorkspaceProvider>
          //       }
          //     />
              
          //     <Route
          //       path="/paymentsuccess"
          //       element={
          //         <WorkspaceProvider>
          //           <PaymentSuccess />
          //         </WorkspaceProvider>
          //       }
          //     />
              
          //     <Route
          //       path="/logout"
          //       element={
          //         <WorkspaceProvider>
          //           <LogOut />
          //         </WorkspaceProvider>
          //       }
          //     />


          //     <Route path="/" element={<PublicRoute />}>
          //       <Route path="/" element={<Home />} />
          //       <Route path="/tools" element={<Tools />} />
          //       <Route path="/landing" element={<Landing />} />
          //       <Route path="/docs" element={<Documentation />} />
          //       <Route path="/api" element={<ApiDocs />} />
          //       <Route path="/blog" element={<Blog />} />
          //       <Route path="/terms" element={<Terms />} />
          //       <Route path="/privacy" element={<Privacy />} />
          //       <Route path="/career" element={<Career />} />
          //       <Route path="/share/:token" element={<ShareProject />} />
                
          //       {/* <Route path="/exclusive-access/login" element={<SignUpLogIn />}/> */}
                
          //       <Route path="/admin" element={<ClientLandingPage />} />
          //       <Route path="/publicview" element={<PublicLinkView />} />
          //       <Route path="/newpage" element={<NewPage />} />
          //     </Route>
          //     <Route path="*" element={<Navigate to="/" replace />} />

          //     <Route path="/presentationgallery" element={<PresentationGallery />} />
          //     <Route path="/blogarticle" element={<BlogArticle />} />
          //     <Route path="/faq" element={<FAQ />} />
          //     <Route path="/termsofservice" element={<TermsOfService />} />
          //     <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          //     <Route path="/contact" element={<Contact />} />

          //     <Route path="/aboutus" element={<Aboutus />} />
          //     <Route path="/safety" element={<Safety />} />
          //     <Route path="/marketingagencies" element={<MarketingAgencies />} />
          //     <Route path="/audiogallery" element={<AudioGallery />} />
          //     <Route path="/imagegallery" element={<ImageGallery />} />
          //     <Route path="/videogallery" element={<VideoGallery />} />
          //     <Route path="/usecases" element={<UseCases />} />
          //     <Route path="/casestudy" element={<CaseStudy />} />
          //   </Routes>