import axios from "axios";
import React, { useState, useEffect } from "react";
import CurrentUsers from "./CurrentUsers";
import WaitListedUsers from "./WaitListedUsers";
import InviteUser from "./InviteUser";
import "bootstrap/dist/css/bootstrap.min.css";
import sourceUrl from "../../api/Source.api";
import Subscription from "./Subscription";
import UserAccessManager from "./UserAccessManager";

const ClientLandingPage = () => {
  const [selected, setSelected] = useState("currentuser");
  const token = localStorage.getItem("token");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const [totalUsersCount,setTotalUsersCount] = useState(0);
  const [loadingForUserManager, setLoadingForUserManager] = useState(true);
  const [errorForUserManager, setErrorForUserManager] = useState(true);
  const [userForUserManager, setUserForUserManager] = useState([
    {
        email: "Shabankhan123456@gmail.com",
        phone_number: "+919044945896",
        inbound_call_count: 0,
        outbound_call_count: 7
    },
    {
      email: "Faheem@gmail.com",
      phone_number: "+17895468328",
      inbound_call_count: 0,
      outbound_call_count: 6
  },
  {
    email: "Faheem@gmail.com",
    phone_number: "+15976569654",
    inbound_call_count: 0,
    outbound_call_count: 5
  },
]);
  const [currentPageForUserManager, setCurrentPagesForUserManager] = useState(1);
  const [totalPagesForUserManager, setTotalPagesForUserManager] = useState([]);
  const [totalUsersCountForUserManager, settotalUsersCountForUserManager] = useState(0);

  // Fetch users when `currentPage` changes
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${sourceUrl}/admin/users`, {
          params: { page: currentPage, pagesize: 100 },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          setUsers(res.data.data.data); // Ensure this is the correct path to your users array
          setTotalPages(Math.ceil(res?.data?.data?.totalRows/100)); // Ensure this matches your API response for total pages
          setTotalUsersCount(res?.data?.data?.totalRows);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          setError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAllUsers();
  }, [currentPage, token]); // Add `token` to the dependency array to prevent stale values

  useEffect(() => {
    const fetchAllUnsubscribeUsers = async () => {
      try {
        setLoadingForUserManager(true);
        const res = await axios.get(`${sourceUrl}/admin/unsubscribeUsers`, {
          params: { page: currentPageForUserManager, pagesize: 100 },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          setUserForUserManager(res.data.data.data); // Ensure this is the correct path to your users array
          setTotalPagesForUserManager(Math.ceil(res?.data?.data?.totalRows/100)); // Ensure this matches your API response for total pages
          settotalUsersCountForUserManager(res?.data?.data?.totalRows);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          setErrorForUserManager(true);
        }
      } finally {
        setLoadingForUserManager(false);
      }
    };

    fetchAllUnsubscribeUsers();
  }, [currentPageForUserManager, token]); 

  if (!token || !isLoggedIn) {
    return (
      <div className="container text-center my-4">
        <h1>You are not logged in yet</h1>
        <p>Please log in to access the admin page.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container text-center my-4">
        <h1>Not Authorized to access the admin page.</h1>
      </div>
    );
  }

  const sidebarStyle = {
    paddingTop: "20px",
    borderRight: "1px solid",
  };

  const navLinkStyle = {
    cursor: "pointer",
    display: "block",
    padding: "10px 15px",
  };

  const activeNavLinkStyle = {
    backgroundColor: "#007bff", // Bootstrap primary color
    color: "white",
    width: "100%",
    textAlign: "start",
    borderRadius: "10px",
  };

  return (
    <div>
      {loading && !users?.length ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              borderBottom: "1px solid",
            }}
          >
            <h1>ModalX Admin Space</h1>
          </div>
          <div className="row" style={{ top: "0" }}>
            <div className="col-md-2" style={sidebarStyle}>
              <ul className="nav flex-column">
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "currentuser"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("currentuser")}
                  >
                    Current Users
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "waitlisted"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("waitlisted")}
                  >
                    Waitlisted Requests
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "invite"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("invite")}
                  >
                    Invite
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "subscription"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("subscription")}
                  >
                    Subscription
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    style={
                      selected === "userAccessManager"
                        ? { ...navLinkStyle, ...activeNavLinkStyle }
                        : navLinkStyle
                    }
                    onClick={() => setSelected("userAccessManager")}
                  >
                    User Access Manager
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-10">
              <div style={{ width: "100%", margin: "auto", padding: "30px" }}>
                {selected === "currentuser" && (
                  <CurrentUsers
                    error={error}
                    setError={setError}
                    users={users}
                    loading={loading}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    totalUsersCount={totalUsersCount}
                  />
                )}
                {selected === "waitlisted" && <WaitListedUsers />}
                {selected === "invite" && <InviteUser />}
                {selected === "subscription" && <Subscription setError={setError} />}
                {selected === "userAccessManager" && <UserAccessManager
                  errorForUserManager={errorForUserManager}
                  setErrorForUserManager={setErrorForUserManager}
                  userForUserManager={userForUserManager}
                  loadingForUserManager={loadingForUserManager}
                  currentPageForUserManager={currentPageForUserManager}
                  setCurrentPagesForUserManager={setCurrentPagesForUserManager}
                  totalPagesForUserManager={totalPagesForUserManager}
                  totalUsersCountForUserManager={totalUsersCountForUserManager} 
                />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientLandingPage;
